<template>
  <div  v-if="donationList.length">
    <v-container>
      <v-row>
        <v-col>
          <div class="heading">
            Recent Donations
            <div class="hLine"></div>
          </div>
        </v-col>
      </v-row>

      <v-slide-group
        class="donation-slide-group donation-container"
        show-arrows
        center-active
        mobile-breakpoint="0"
      >
        <template v-slot:prev>
          <v-icon color="#D30024">mdi-chevron-left</v-icon>
        </template>
        <template v-slot:next>
          <v-icon color="#D30024">mdi-chevron-right</v-icon>
        </template>

        <v-slide-item
          v-for="(item, i) in donationList"
          :key="i"
          class="slide-item"
        >
          <v-card class="donation-card" elevation="2">
            <v-card-title>
              <v-row align="center" >
                <v-col cols="6" class="text-center">
                  <v-sheet
                    color="rgba(56, 167, 55, 0.12)"
                    class="amount-box"
                    rounded
                  >
                    <span class="amount-text">${{ item.original_amount }}</span>
                  </v-sheet>
                </v-col>
                <v-col cols="8">
                  <div class="donor-name">
                    {{ item.donor_name }}
                  </div>
                  <div class="donation-time">
                    {{ item.days_before }} days ago
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pt-0">
              <div class="donor-message">
                {{ item.donor_special_message }}
              </div>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-container>
  </div>
</template>

<script>
import Axios from "@/api/BaseAxios";
import { mapGetters } from "vuex";
import { API_PUBLIC_RECENT_DONATION } from "@/constants/APIUrls";
export default {
  name: "RecentDonation",
  data() {
    return {
      donationList: [],
      date: new Date(),
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getcampaignType() {
      return this.getCampaign.type;
    },
    ids() {
      let panel_id = "";
      if (this.getcampaignType.type === "student") {
        panel_id = this.getcampaignType.student_id;
      } else if (this.getcampaignType.type === "teacher") {
        panel_id = this.getcampaignType.teacher_id;
      }
      return panel_id;
    },
  },
  methods: {
    getDonationDetails() {
      const successHandler = (res) => {
        this.donationList = res.data.donation_list;
        // console.log(res.data.donation_list, "don");
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["campaign_id"] = this.getCampaign.campaign_detail.id;
      formData[this.getcampaignType.type + "_id"] = this.ids;
      Axios.request_GET(
        API_PUBLIC_RECENT_DONATION,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    // console.log(this.getCampaign, "fffffffff");
    this.getDonationDetails();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.donation-container{
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
}
.heading {
  font-size: 26px;
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  color: #2c1963;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  place-content: center;
}

.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-left: 15px;
}

.donation-slide-group {
  overflow-x: auto !important;
  padding-left: 8px;
  padding-right: 8px;
  height: fit-content;
}

.slide-item {
  padding: 8px;
  min-width: 270px;
  max-width: 360px;
}

.donation-card {
  height: auto;
  border-radius: 14px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  transition: transform 0.2s ease;
  margin: 10px;
}

.donation-card:hover {
  transform: translateY(-4px);
}

.amount-box {
  padding: 6px 12px;
  display: inline;
  flex-wrap: nowrap;
  font-size: 16px;
  font-weight: 600;
  color: #38a737;
}

.amount-text {
  font-size: 16px;
  font-weight: bold;
}

.donor-name {
  font-size: 18px;
  font-weight: 700;
  color: #1d1d1d;
  line-height: 1.2;
}

.donation-time {
  font-size: 13px;
  color: #747474;
  margin-top: 4px;
}

.donor-message {
  font-size: 15px;
  color: #2d2d2d;
  padding-left: 8px;
  padding-right: 8px;
  word-break: break-word;
}

/* Mobile fixes */
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }

  .hLine {
    width: 30px;
    height: 3px;
    margin-left: 10px;
  }

  .slide-item {
    min-width: 260px;
  }

  .donor-name {
    font-size: 16px;
  }

  .amount-box {
    font-size: 14px;
  }

  .donor-message {
    font-size: 14px;
  }
}

</style>
